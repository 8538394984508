import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'
import Container from '../components/Container'
import ContainerWrapper from '../components/ContainerWrapper'
import PageTitle from '../components/PageTitle'
import PureTable from '../components/PureTable'
import orderBy from 'lodash/orderBy'
import styled from 'styled-components'
import config from '../utils/siteConfig'

import SEO from '../components/SEO'

import { Link, graphql } from 'gatsby'

import { dataOrganizer } from '../utils/dataOrganizer'

// const Subtitle = styled.h2`
//   font-size: 1.5em;
//   font-weight: 600;
//   margin: 2rem 0 1rem;
//   text-transform: none;
// `

const Paragraph = styled.p`
  margin: 1rem 0 1rem 0;
  line-height: 1.6;
`

const ParagraphBottom = styled.p`
  margin: 20px auto 0 auto;
  line-height: 1.6;
`

const CenteredSection = styled.section`
  margin: 0px auto;
`

const LastUpdatedInformationSection = styled.div`
  margin: 0px auto;
  margin-top: 1.5em;
  text-align: center;
  color: #514848;
  font-style: italic;
  font-size: 13px;
`

const ImageIcon = styled.img`
  width: 13px;
  height: 13px;
  margin-right: 16px;
  padding: 0;
  border: 0;
  display: inline-block;
`

const importAll = (require) =>
  require.keys().reduce((acc, next) => {
    acc[next.replace('./', '')] = require(next)
    return acc
  }, {})

const icons = importAll(
  require.context('../images/store-icons', false, /\.(png|jpe?g|svg)$/)
)

const Index = ({ data, theme }) => {
  const { groupedByName, formattedLastUpdate } = dataOrganizer(data)

  const firstStore = (store) => {
    return orderBy(
      groupedByName[store],
      ['cashback_type', 'cashback_number'],
      ['asc', 'desc']
    )[0]
  }

  const tableBySite = Object.keys(groupedByName).map((store, index) => {
    const mainStore = firstStore(store)

    return (
      <tr key={index + 1}>
        <td>
          <ImageIcon
            src={icons[`${mainStore.slug}.png`]?.default}
            alt={mainStore.name}
            width="!3"
            height="13"
          />
          <Link to={`/loja/${mainStore.slug}`}>{store}</Link>
        </td>
        <td>{mainStore.cashback}</td>
      </tr>
    )
  })

  const postNode = {
    title: `Top Cashback - Cashback Ranking`,
  }

  return (
    <Layout>
      <Helmet>
        <title lang={config.language}>
          Top Cashback - Cashback Ranking - Compare cashbacks com a nossa
          extensão
        </title>

        <meta charset="utf-8"></meta>
        <link rel="canonical" href="https://www.cashbackranking.com.br/" />
        <meta httpEquiv="content-language" content="pt-br" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1"></meta>
        <meta name="HandheldFriendly" content="True"></meta>
        <meta name="MobileOptimized" content="320"></meta>
        <meta
          name="description"
          content="Top Cashback - Compare cashback no Cashback Ranking: As melhores ofertas da internet! - Extensão Cashback Ranking"
        />
      </Helmet>

      <SEO postNode={postNode} pagePath="index" customTitle />

      <Container>
        <ContainerWrapper>
          <PageTitle>Top Cashbacks</PageTitle>
          <Paragraph>
            Listamos as melhores taxas de cashback (dinheiro de volta) que você
            pode receber nas principais lojas do Brasil. Acesse o link da loja
            para ver o comparativo. Não achou alguma loja?{' '}
            <Link to="/contato">Fale conosco</Link>.
          </Paragraph>
          <CenteredSection>
            <PureTable>
              <thead>
                <tr>
                  <th>Loja</th>
                  <th>Maior Cashback</th>
                </tr>
              </thead>
              <tbody>{tableBySite}</tbody>
            </PureTable>
            <LastUpdatedInformationSection>
              Última atualização: {formattedLastUpdate}
            </LastUpdatedInformationSection>
          </CenteredSection>

          <ParagraphBottom>
            Procurando Cupons ao invés de Cashbacks ? &nbsp;
            <a
              href="https://ebaoferta.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Êba, oferta!
            </a>
          </ParagraphBottom>
        </ContainerWrapper>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    allCashback {
      edges {
        node {
          id
          cashback_type
          cashback_number
          cashback_id
          cashback
          name
          short_url
          site
          slug
          store_icon_url
          store_url
          updated_at
          url
          last_update
        }
      }
    }
  }
`

export default Index
